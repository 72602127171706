import { isSameMonth, isSameDay } from 'date-fns';
import last from 'lodash/last';

export const getCellClassName = (dates, day, monthStart) => {
  if (!isSameMonth(day, monthStart)) {
    return 'disabled';
  }

  if (isStayStart(dates, day)) {
    return 'stay-start';
  }

  if (isStayInner(dates, day)) {
    return 'stay-inner';
  }

  if (isStayEnd(dates, day)) {
    return 'stay-end';
  }

  return '';
};

const isStayStart = (dates, day) => {
  const stayStartDates = dates.map(datesArray => datesArray[0]);
  return stayStartDates.some(element => isSameDay(element, day));
};

const isStayEnd = (dates, day) => {
  const stayEndDates = dates.map(datesArray => last(datesArray));
  return stayEndDates.some(element => isSameDay(element, day));
};

const isStayInner = (dates, day) => {
  const stayInnerDates = dates.map(datesArray => datesArray.slice(1, -1)).flat();
  return stayInnerDates.some(element => isSameDay(element, day));
};
