import $ from 'jquery';
import React from 'react';
import imagesLoaded from 'imagesLoaded';
import ReactDOM from 'react-dom';
import YearCalendar from './components/YearCalendar';

$(function() {
  $('.apartment-list [data-toggle]').each((_index, toggleElement) => {
    const toggle = $(toggleElement);
    const tabName = toggle.data('toggle');
    const isGalleryTab = tabName === 'gallery';
    const targetTabSelector = `[data-tab=${tabName}]`;
    const tab = toggle.closest('li').find(targetTabSelector);

    if (isGalleryTab) {
      const imgLoad = imagesLoaded(tab[0]);

      imgLoad.on('always', () => {
        tab.children('.images-loading').hide();
        tab.children('[data-masonry]').css({ display: 'flex' });
      });
    }

    toggle.on('click', event => {
      event.preventDefault();

      $('[data-tab].visible')
        .not(tab)
        .hide();

      $('[data-toggle].active')
        .not(toggle)
        .removeClass('active');

      if (toggle.hasClass('active')) {
        toggle.removeClass('active');
        tab.hide();
      } else {
        toggle.addClass('active');
        tab.addClass('visible').show();

        if (isGalleryTab) {
          window.dispatchEvent(new Event('resize'));
        }

        $('body').animate(
          {
            scrollTop: toggle.closest('.apartment-nav').offset().top - 70,
          },
          400,
          'swing'
        );
      }
    });
  });

  document.querySelectorAll('.calendar').forEach(element => {
    ReactDOM.render(<YearCalendar stays={element.dataset.stays} lang={document.body.dataset.lang} />, element);
  });
});
