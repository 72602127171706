import React from 'react';
import { format, startOfWeek, endOfMonth, endOfWeek, startOfMonth, addDays } from 'date-fns';
import { getCellClassName } from './helpers';

class CalendarMonth extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      month: props.month || new Date(),
      locale: null,
    };
  }

  componentDidMount() {
    const locale = document.body.dataset.lang === 'pl' ? 'pl' : 'en';
    const localeData = require(`date-fns/locale/${locale}`);
    this.setState(state => ({ ...state, locale: localeData }));
  }

  renderHeader = () => (
    <div className="calendar-month-header">
      <span>{format(this.state.month, 'MMMM YYYY', { locale: this.state.locale })}</span>
    </div>
  );

  renderDays() {
    const { locale } = this.state;
    const dateFormat = 'dd';
    const days = [];

    let startDate = startOfWeek(this.state.month, { weekStartsOn: 1 });

    for (let i = 0; i < 7; i++) {
      days.push(<th key={i}>{format(addDays(startDate, i), dateFormat, { locale })}</th>);
    }

    return (
      <thead>
        <tr>{days}</tr>
      </thead>
    );
  }

  renderCells() {
    const { locale, month } = this.state;
    const { dates } = this.props;
    const monthStart = startOfMonth(month);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });
    const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });

    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = '';

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, 'D', { locale });

        days.push(
          <td className={getCellClassName(dates, day, monthStart)} key={day}>
            <span className="number">{formattedDate}</span>
          </td>
        );
        day = addDays(day, 1);
      }
      rows.push(<tr key={day}>{days}</tr>);
      days = [];
    }

    return <tbody>{rows}</tbody>;
  }

  render() {
    if (!this.state.locale) {
      return null;
    }

    return (
      <div className="calendar-month">
        {this.renderHeader()}
        <table>
          {this.renderDays()}
          {this.renderCells()}
        </table>
      </div>
    );
  }
}

export default CalendarMonth;
